import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

const ContentEditor = ({ value, onEditorChange }) => {
  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={value}
      init={{
        height: '100%',
        menubar: true,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'media',
          'charmap',
          'table',
        ],
        toolbar: `
          undo redo | formatselect fontselect | bold italic underline strikethrough | 
          forecolor backcolor removeformat | alignleft aligncenter alignright alignjustify | 
          bullist numlist outdent indent | link image media anchor | table
        `,
        font_formats: `
          Cinzel=Cinzel,serif;
          Andale Mono=andale mono,times;
          Arial=arial,helvetica,sans-serif;
          Courier New=courier new,courier,monospace;
          Times New Roman=times new roman,times,serif;
        `,
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');
          body { font-family: 'Cinzel', serif; font-size: 14px; }
        `,
      }}
      onEditorChange={onEditorChange}
    />
  );
};

export default ContentEditor;
